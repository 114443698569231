import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { parseCookies } from "nookies";

export function getApolloClient(ctx?: any) {
  const authToken = parseCookies(ctx)[process.env.COOKIE];

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createUploadLink({
      uri: process.env.GRAPHQL_URL,
      credentials: "include",
      headers: {
        Authorization: authToken ? authToken : "",
      },
    }),
    cache: new InMemoryCache({
      dataIdFromObject: (object) => `${object.__typename}${object.id}`,
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = getApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = initializeApollo(initialState);
  return store;
}
