import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient";
import Head from "next/head";
import "../styles/globals.css";
import "../styles/tailwind.css";
import Bugsnag from "@bugsnag/js";
import Error from "./_error";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ApolloProvider client={apolloClient}>
        <Head>
          <title>Kohai Admin</title>
        </Head>
        <Component {...pageProps} />
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
